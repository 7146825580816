.works__h2 {
  color: var(--main-color);
  margin-bottom: 16px;
}

.card-list {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 24px;

  counter-reset: card-list-item-counter;
}

.card-list__item {
  flex-basis: 100%;
  background: var(--bright-background-color);
  border-radius: 16px;
  overflow: hidden;

  counter-increment: card-list-item-counter;
}

.card {
  margin: 0;
}

.card__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card__desc {
  height: 100%;
  padding: 32px 12px;
}

.card__header {
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.card__header::before {
  content: counter(card-list-item-counter) ". ";;
}

.card__item {
  list-style-type: disc;
  list-style-position: inside;
}

.card__item::marker {
  content: '\2022';
}

.card__text {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card__text:not(:last-child) {
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .works__h2 {
    text-align: center;
  }

  .card-list__item {
    flex-basis: calc((100% - 1 * 24px) / 2);
  }
}

@media (min-width: 1024px) {
  .works__h2 {
    margin-bottom: 40px;
  }

  .card-list {
    gap: 44px;
  }

  .card-list__item {
    flex-basis: calc((100% - 2 * 44px) / 3);
  }
}
