.footer {
  background: #9b6109;
  padding: 40px 0;
  text-align: center;
}

.copyright {
  margin-bottom: 24px;
}

.footer__txt {
  color: #fff;
  line-height: 1.3
}

.footer__legal>a {
  display: inline;
  text-decoration: underline;
}

.footer__legal>a:last-child {
  margin-left: 24px;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 24px;
}

.socials__link {
  width: 36px;
  height: 36px;
}