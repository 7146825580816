.hero {
  padding-top: 2.0625em;
  overflow-x: clip;
  overflow-y: visible;
}

.hero__header {
  display: flex;
  justify-content: center;
}

.hero__logo {
  width: 88px;
  height: 40px;
  display: block;
  margin: 0.8125em 1.93753em auto 0.625em;

  margin-top: 0.8125;
  flex-shrink: 0;
  align-self: start;
}

.hero__title {
  text-align: left;
  margin: 0;
}

.hero__text {
  text-align: center;
  margin: 16px 0 0;
}

.hero__img,
.hero__contact-nav {
  margin-top: 40px;
}

.hero__view-nav {
  margin: 40px auto 0;
  justify-content: center;
}

.hero__text.hero__text--small {
  font-size: 16px;
}

.hero__accent-text {
  position: relative;
  padding: 10px 4px;
  background: url('../images/ellipse.svg') no-repeat center / contain;
}

.hero__background {
  position: relative;
  margin-top: 1.93753em;
  margin-left: auto;
  padding-left: 30px;
  width: fit-content;
}

.hero__background::before,
.hero__background::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.hero__background::before {
  width: calc(0.226315789 * (100% - 30px));
  height: 26.8421053%;
  top: 12.368421053%;
  /* 0.2 = 76px / 380px */
  left: calc(-0.2 * (100% - 30px) + 30px);
  transform: rotate(30deg);
  background: url('../../assets/images/hero-circle.svg') no-repeat center /
    contain;
}

.hero__background::after {
  width: calc(0.884210526 * (100% - 30px));
  height: 78.421052632%;
  bottom: -3.947368421%;
  /* 0.118421053 = 45px / 380px */
  left: calc(-0.118421053 * (100% - 30px) + 30px);
  margin-left: auto;
  background: url('../images/hero-circle-2.svg') no-repeat center / contain;
}

.hero__background--desktop {
  display: none;
}

.hero__image {
  display: block;
  width: 100%;
  height: 100%;
  min-width: 380px;
  min-height: 380px;
  max-width: 632px;
  max-height: 632px;

  object-fit: cover;
  object-position: top left;
  overflow: visible;
}

.hero-nav__link span::before,
.view::before {
  content: '';
  position: absolute;
}

.hero-nav__link {
  display: flex;
  align-items: center;
  gap: 7px;
  color: var(--accent-color);
  font-weight: 700;
}

.hero-nav__link span {
  position: relative;
}

.hero-nav__link span::before {
  left: 0;
  bottom: 0;
  width: 100%;
  transition: all 0.35s ease;
  height: 1px;
  background: var(--accent-color);
  transform: scale(0);
}

.hero-nav__link:hover span::before {
  transform: scale(1);
}

@media screen and (min-width: 410px) {
  .hero__background {
    margin-right: auto;
  }
}

@media screen and (min-width: 768px) {
  .hero__header {
    display: block;
  }

  .hero__logo {
    margin: auto;
  }

  .hero__title {
    text-align: center;
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .hero {
    padding-top: 6.25em;
    padding-bottom: 1.6875em;
  }

  .hero__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4%;
  }

  .hero__background--mobile {
    display: none;
  }

  .hero__background--desktop {
    display: block;
    margin: 0;
    padding: 0;
  }

  .hero__background::before {
    width: calc(0.226315789 * 100%);
    /* height: 26.8421053%;
    top: 12.368421053%; */
    top: 8.386075949%;
    /* 0.2 = 76px / 380px */
    left: auto;
    right: -6.594632768%;
    transform: rotate(0deg);
  }

  .hero__background::after {
    width: calc(0.884210526 * 100%);
    height: 78.421052632%;
    bottom: -3.947368421%;
    /* 0.118421053 = 45px / 380px */
    left: calc(-0.118421053 * 100%);
  }

  .hero__wrapper {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    max-width: 537px;
  }

  .hero__view-nav {
    margin-left: 0;
    width: 100%;
    justify-content: start;
  }

  .hero__logo {
    margin: 0;
  }

  .hero__title {
    margin-top: 40px;
  }

  .hero__title,
  .hero__text {
    text-align: left;
  }

  .hero__contact-nav {
    flex-direction: row;
    gap: 8px;
  }
}
