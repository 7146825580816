.swiper {
  margin-top: 80px;
  position: relative;
}

.swiper--menu {
  margin-top: 6.5em;
}

.h2--swiper {
  margin-bottom: 30px;
  text-align: center;
}

.swiper__btn {
  margin-top: 132px;
  width: 80px;
  height: 80px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff3df;
  border-radius: 100%;
  transition: background 0.35s;
  flex-shrink: 0;
}

.swiper__btn:disabled,
.swiper__btn:disabled:hover {
  cursor: auto;
  background: #faf6ee;
}

.swiper__btn:focus,
.swiper__btn:hover {
  background: #ffe5b9;
}

.swiper__btn:last-of-type > svg {
  transform: rotate(180deg);
}

.swiper__wrapper {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.swiper__list {
  padding: 0 27px;
  display: flex;
  overflow-x: auto;
  gap: 16px;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.swiper__list::-webkit-scrollbar {
  display: none;
}

.swiper__item {
  flex-basis: 97%;
  flex-shrink: 0;
  flex-grow: 0;
  scroll-snap-align: center;
}

.swiper__figure {
  margin: 0;
  border-radius: 16px;
  overflow: hidden;
}

.swiper__img {
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
  width: 100%;
  height: 280px;
}

.swiper__caption {
  padding: 32px 22px 0;
  min-height: 180px;
  height: 100%;
  font-family: Fira Sans;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0;
  text-align: center;
  background: #fff4e2;
}

.swiper__title {
  font-weight: 700;
  letter-spacing: 0;
}

.swiper__desc {
  margin: 8px 0 0;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .swiper__wrapper {
    padding: 0 27px;
  }

  .swiper__btn {
    display: inline-flex;
  }

  .swiper__wrapper {
    margin: auto;
  }

  .swiper__list {
    padding: 0;
  }

  .swiper__item {
    flex-basis: 100%;
    scroll-snap-align: start;
  }
}

@media screen and (min-width: 1024px) {
  .swiper {
    margin-top: 100px;
  }

  .swiper--menu {
    margin-top: 9.375em;
  }

  .swiper__wrapper {
    padding: 0 76px;
    max-width: 1440px;
  }

  .swiper__item {
    flex-basis: calc((100% - 16px) / 2);
    scroll-snap-align: start;
  }
}

@media screen and (min-width: 1300px) {
  .swiper__item {
    flex-basis: calc((100% - (2 * 16px)) / 3);
  }
}
