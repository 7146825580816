:root {
  --main-color: #000;
  --bright-background-color: #fdf4e4;
  --background-light-color: #fff4e2;
  --border-color: #fadbae;
  --accent-color: #9b6109;
  --font-main: 'Fira Sans';
  --font-size: 1em;
  --color-text-primary: #000;
  --color-accent-primary: #9b6109;
  --color-accent-secondary: #fdf4e4;
}

@font-face {
  font-family: Fira Sans;
  font-weight: 400;
  font-style: normal;
  src: url(../../assets/fonts/FiraSans-Regular.eot);
  src: url(../../assets/fonts/FiraSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: Fira Sans;
  font-weight: 700;
  font-style: normal;
  src: url(../../assets/fonts/FiraSans-Bold.eot);
  src: url(../../assets/fonts/FiraSans-Bold.woff2) format('woff2');
}

body,
html {
  height: 100%;
}

p {
  margin-top: 0;
  margin-bottom: 15px;
}

path {
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

body {
  font-size: var(--font-size);
  line-height: 1.54;
  min-width: 320px;
  position: relative;
  font-family: var(--font-main);
  color: var(--color-text-primary);
  margin: 0;
}

::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

::-moz-placeholder {
  color: #666;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #666;
  opacity: 1;
}

::placeholder {
  color: #666;
  opacity: 1;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
  display: block;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
  display: block;
}

.image img {
  display: block;
  width: 100%;
}

.clearfix:after {
  clear: both;
  content: '';
  display: table;
}

h2 li,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button,
input,
select,
textarea {
  outline: 0;
  border: none;
  margin: 0;
  padding: 0;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}

a {
  color: inherit;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-decoration: none;
  outline: 0;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
  background: 0 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

textarea {
  resize: none;
}

body.hidden {
  overflow: hidden;
}

.section {
  /* TODO need to remove important ones after menu changes */
  margin-top: 80px !important;
}

.header {
  padding: 25px 0;
}

.header-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-list li + li {
  margin-left: 25px;
}

.main-list a {
  display: inline-block;
  position: relative;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger-inner {
  background-color: #333;
}

.hamburger-inner::after,
.hamburger-inner::before {
  background-color: #333;
}

.button {
  width: 100%;
  max-width: 13.5em;
  padding: 0.9375em 0.6875em;
  color: currentColor;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
  text-align: center;
  border: 1px solid var(--color-accent-primary);
  border-radius: 0.25em;
}

.button--primary {
  color: var(--color-accent-secondary);
  background-color: var(--color-accent-primary);
}

.button--secondary {
  color: var(--color-accent-primary);
  background-color: var(--color-accent-secondary);
}

.button--primary:hover {
  background: #744907;
}

.button--secondary:hover {
  background: #ffe5b9;
}

.h1 {
  font-size: 64px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 42px;
}

.h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 21px;
}

.h3 {
  font-size: 24px;
}

.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 27px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .container {
    padding: 0 76px;
  }

  .section {
    /* TODO need to remove important ones after menu changes */
    margin-top: 100px !important;
  }
}

.work {
  margin-bottom: 86px;
}

.work-list {
  display: none;
}

.work-bg {
  display: none;
}

.work-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.work-item {
  width: 50%;
}

.work-img {
  position: relative;
  z-index: 1;
  max-width: 635px;
}

.work-img__bg {
  display: block;
  border-radius: 50%;
  margin: 0 auto;
}

.work-img__circle {
  position: absolute;
  bottom: -32px;
  right: -81px;
  z-index: -1;
}

.work-img__circle2 {
  display: none;
}

.work-info {
  max-width: 403px;
  margin-left: auto;
  margin-right: 71px;
  margin-top: 7px;
}

.view {
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}

.view-circle2 {
  display: none;
}

.view-circle {
  position: absolute;
  z-index: -2;
  left: 50%;
  bottom: 57.5px;
  margin-left: -89px;
}

.view::before {
  width: 100%;
  background: #fdf4e4;
  height: 281px;
  border-top: 1px solid #9b6109;
  left: 0;
  top: 136px;
}

.view-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.view-img {
  padding-right: 46px;
}

.view-img img {
  display: block;
  margin-left: auto;
}

.view-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 24px;
}

.view-head {
  margin-bottom: 24px;
  font-weight: 700;
  color: #9b6109;
  font-size: 32px;
  margin-bottom: 18px;
}

.view-content {
  position: relative;
  top: -14px;
  padding-left: 12px;
}

.view-content,
.view-img {
  width: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
}

.contact {
  margin-bottom: 40px;
}

.contact-icon {
  margin-bottom: 54px;
  display: block;
}

.contact-icon img {
  display: block;
  margin: 0 auto;
}

.contact-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.inner .feature-img img {
  width: 464px;
  left: 9px;
}

@media only screen and (max-width: 1300px) {
  .work-img__circle {
    width: 46vw;
  }
}

@media only screen and (max-width: 1300px) {
  .work-info {
    margin-left: auto;
    margin-right: auto;
  }

  .work-img__circle {
    right: -30px;
    bottom: -20px;
  }
}

@media only screen and (max-width: 1023px) {
  .btn {
    font-size: 16px;
  }

  .h1 {
    font-size: 36px;
    margin-bottom: 24px;
  }

  .work-info {
    margin-top: 0;
  }

  .work-item {
    padding: 0 15px;
  }

  .work-row {
    margin: 0 -15px;
  }

  .work-img__circle {
    right: -15px;
  }

  .view-row {
    left: 0;
  }

  .view-img {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    font-size: 20px;
  }

  .inner .work {
    margin-top: -65px;
    padding-bottom: 85px;
  }

  .inner .feature {
    margin-bottom: 0;
  }

  .inner .feature-benefit {
    top: -9px;
  }

  .inner .feature-img img {
    width: 397px;
  }

  .inner .feature-item:first-child {
    margin-bottom: 80px;
  }

  .view {
    margin-bottom: 112px;
  }

  .view::before {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .view-circle {
    display: none;
  }

  .view-circle2 {
    display: block;
    position: absolute;
    z-index: -2;
    left: 0;
    bottom: -71px;
  }

  .view-row {
    left: -38px;
  }

  .view-head {
    line-height: 1.25;
    margin-bottom: 22px;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
  }

  .view-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 16px;
  }

  .view-nav .btn {
    width: 100%;
    max-width: 216px;
  }

  .view-img {
    width: 170px;
    margin-right: 26px;
    padding-right: 0;
    -ms-flex-negative: 0;
  }

  .view-img img {
    max-width: none;
    width: 100%;
  }

  .view-content {
    text-align: center;
    width: 216px;
    -ms-flex-negative: 0;
    top: -10px;
    padding-left: 0;
  }

  .contact-icon {
    display: none;
  }

  .work {
    position: relative;
    overflow: hidden;
    padding-top: 91px;
    z-index: 1;
    margin-bottom: 0;
    padding-bottom: 64px;
  }

  .work-list {
    display: block !important;
    padding-left: 12px !important;
  }

  .work-bg {
    display: block;
    position: absolute;
    top: 22px;
    left: -235px;
    max-width: none;
    pointer-events: none;
  }

  .work-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 109px;
  }

  .work-item {
    width: 100%;
  }

  .work-item:last-child {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .work-info {
    padding-left: 12px;
    margin-bottom: 30px;
    margin-left: 0;
  }

  .work-img {
    width: 380px;
    left: -118px;
    z-index: auto;
  }

  .work-img__circle {
    display: none;
  }

  .work-img__circle2 {
    display: block;
    position: absolute;
    z-index: -1;
    right: -88px;
    bottom: -55px;
  }
}

@media only screen and (max-width: 374px) {
  .view-content {
    width: 175px;
  }

  .view-img {
    margin-right: 14px;
  }

  .btn {
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .h1,
  .h2 {
    font-size: 28px;
  }
}
