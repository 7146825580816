@import './styles/main';
@import './styles/how-it-works';
@import './styles/feature';
@import './styles/hero';
@import '../assets/styles/footer';
@import '../assets/styles/swiper';

.h2--swiper {
  margin-bottom: 40px;
}

.text {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
