:root {
  --main-color: #000;
  --bright-background-color: #fdf4e4;
  --background-light-color: #fff4e2;
  --border-color: #fadbae;
  --accent-color: #9b6109;
  --font-main: "Fira Sans";
  --font-size: 1em;
  --color-text-primary: #000;
  --color-accent-primary: #9b6109;
  --color-accent-secondary: #fdf4e4;
}

@font-face {
  font-family: Fira Sans;
  font-weight: 400;
  font-style: normal;
  src: url("FiraSans-Regular.95bc810b.eot");
  src: url("FiraSans-Regular.f7fcc28b.woff2") format("woff2");
}

@font-face {
  font-family: Fira Sans;
  font-weight: 700;
  font-style: normal;
  src: url("FiraSans-Bold.6210eb8a.eot");
  src: url("FiraSans-Bold.f12c8e87.woff2") format("woff2");
}

body, html {
  height: 100%;
}

p {
  margin-top: 0;
  margin-bottom: 15px;
}

path {
  transition: all .35s;
}

body {
  font-size: var(--font-size);
  min-width: 320px;
  line-height: 1.54;
  font-family: var(--font-main);
  color: var(--color-text-primary);
  margin: 0;
  position: relative;
}

::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

::placeholder {
  color: #666;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}

::placeholder {
  color: #666;
  opacity: 1;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
  display: block;
}

.image img {
  width: 100%;
  display: block;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

h2 li, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button, input, select, textarea {
  border: none;
  outline: 0;
  margin: 0;
  padding: 0;
}

*, :after, :before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
}

a {
  color: inherit;
  outline: 0;
  text-decoration: none;
  transition: all .3s linear;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
  background: none;
}

select {
  appearance: none;
}

select::-ms-expand {
  display: none;
}

textarea {
  resize: none;
}

body.hidden {
  overflow: hidden;
}

.section {
  margin-top: 80px !important;
}

.header {
  padding: 25px 0;
}

.header-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.main-list {
  align-items: center;
  display: flex;
}

.main-list li + li {
  margin-left: 25px;
}

.main-list a {
  display: inline-block;
  position: relative;
}

.hamburger {
  cursor: pointer;
  display: none;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  background-color: #333;
}

.button {
  color: currentColor;
  text-align: center;
  border: 1px solid var(--color-accent-primary);
  border-radius: .25em;
  width: 100%;
  max-width: 13.5em;
  padding: .9375em .6875em;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.button--primary {
  color: var(--color-accent-secondary);
  background-color: var(--color-accent-primary);
}

.button--secondary {
  color: var(--color-accent-primary);
  background-color: var(--color-accent-secondary);
}

.button--primary:hover {
  background: #744907;
}

.button--secondary:hover {
  background: #ffe5b9;
}

.h1 {
  margin-bottom: 42px;
  font-size: 64px;
  font-weight: 700;
  line-height: 1;
}

.h2 {
  margin-bottom: 21px;
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
}

.h3 {
  font-size: 24px;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 27px;
}

@media (width >= 1024px) {
  .container {
    padding: 0 76px;
  }

  .section {
    margin-top: 100px !important;
  }
}

.work {
  margin-bottom: 86px;
}

.work-list, .work-bg {
  display: none;
}

.work-row {
  align-items: center;
  display: flex;
}

.work-item {
  width: 50%;
}

.work-img {
  z-index: 1;
  max-width: 635px;
  position: relative;
}

.work-img__bg {
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.work-img__circle {
  z-index: -1;
  position: absolute;
  bottom: -32px;
  right: -81px;
}

.work-img__circle2 {
  display: none;
}

.work-info {
  max-width: 403px;
  margin-top: 7px;
  margin-left: auto;
  margin-right: 71px;
}

.view {
  z-index: 1;
  margin-bottom: 40px;
  position: relative;
}

.view-circle2 {
  display: none;
}

.view-circle {
  z-index: -2;
  margin-left: -89px;
  position: absolute;
  bottom: 57.5px;
  left: 50%;
}

.view:before {
  background: #fdf4e4;
  border-top: 1px solid #9b6109;
  width: 100%;
  height: 281px;
  top: 136px;
  left: 0;
}

.view-row {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.view-img {
  padding-right: 46px;
}

.view-img img {
  margin-left: auto;
  display: block;
}

.view-nav {
  align-items: center;
  gap: 24px;
  display: flex;
}

.view-head {
  color: #9b6109;
  margin-bottom: 18px;
  font-size: 32px;
  font-weight: 700;
}

.view-content {
  padding-left: 12px;
  position: relative;
  top: -14px;
}

.view-content, .view-img {
  flex-grow: 0;
  flex-shrink: 0;
  width: 50%;
  position: relative;
}

.contact {
  margin-bottom: 40px;
}

.contact-icon {
  margin-bottom: 54px;
  display: block;
}

.contact-icon img {
  margin: 0 auto;
  display: block;
}

.contact-nav {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.inner .feature-img img {
  width: 464px;
  left: 9px;
}

@media only screen and (width <= 1300px) {
  .work-img__circle {
    width: 46vw;
  }

  .work-info {
    margin-left: auto;
    margin-right: auto;
  }

  .work-img__circle {
    bottom: -20px;
    right: -30px;
  }
}

@media only screen and (width <= 1023px) {
  .btn {
    font-size: 16px;
  }

  .h1 {
    margin-bottom: 24px;
    font-size: 36px;
  }

  .work-info {
    margin-top: 0;
  }

  .work-item {
    padding: 0 15px;
  }

  .work-row {
    margin: 0 -15px;
  }

  .work-img__circle {
    right: -15px;
  }

  .view-row {
    left: 0;
  }

  .view-img {
    padding-right: 30px;
  }
}

@media only screen and (width <= 767px) {
  .btn {
    font-size: 20px;
  }

  .inner .work {
    margin-top: -65px;
    padding-bottom: 85px;
  }

  .inner .feature {
    margin-bottom: 0;
  }

  .inner .feature-benefit {
    top: -9px;
  }

  .inner .feature-img img {
    width: 397px;
  }

  .inner .feature-item:first-child {
    margin-bottom: 80px;
  }

  .view {
    margin-bottom: 112px;
  }

  .view:before {
    top: 50%;
    transform: translateY(-50%);
  }

  .view-circle {
    display: none;
  }

  .view-circle2 {
    z-index: -2;
    display: block;
    position: absolute;
    bottom: -71px;
    left: 0;
  }

  .view-row {
    left: -38px;
  }

  .view-head {
    max-width: 150px;
    margin-bottom: 22px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.25;
  }

  .view-nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .view-nav .btn {
    width: 100%;
    max-width: 216px;
  }

  .view-img {
    -ms-flex-negative: 0;
    width: 170px;
    margin-right: 26px;
    padding-right: 0;
  }

  .view-img img {
    width: 100%;
    max-width: none;
  }

  .view-content {
    text-align: center;
    -ms-flex-negative: 0;
    width: 216px;
    padding-left: 0;
    top: -10px;
  }

  .contact-icon {
    display: none;
  }

  .work {
    z-index: 1;
    margin-bottom: 0;
    padding-top: 91px;
    padding-bottom: 64px;
    position: relative;
    overflow: hidden;
  }

  .work-list {
    padding-left: 12px !important;
    display: block !important;
  }

  .work-bg {
    pointer-events: none;
    max-width: none;
    display: block;
    position: absolute;
    top: 22px;
    left: -235px;
  }

  .work-row {
    flex-direction: column;
    margin-bottom: 109px;
  }

  .work-item {
    width: 100%;
  }

  .work-item:last-child {
    order: -1;
  }

  .work-info {
    margin-bottom: 30px;
    margin-left: 0;
    padding-left: 12px;
  }

  .work-img {
    z-index: auto;
    width: 380px;
    left: -118px;
  }

  .work-img__circle {
    display: none;
  }

  .work-img__circle2 {
    z-index: -1;
    display: block;
    position: absolute;
    bottom: -55px;
    right: -88px;
  }
}

@media only screen and (width <= 374px) {
  .view-content {
    width: 175px;
  }

  .view-img {
    margin-right: 14px;
  }

  .btn {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
  }

  .h1, .h2 {
    font-size: 28px;
  }
}

.works__h2 {
  color: var(--main-color);
  margin-bottom: 16px;
}

.card-list {
  counter-reset: card-list-item-counter;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 24px;
  width: 100%;
  display: flex;
}

.card-list__item {
  background: var(--bright-background-color);
  counter-increment: card-list-item-counter;
  border-radius: 16px;
  flex-basis: 100%;
  overflow: hidden;
}

.card {
  margin: 0;
}

.card__img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.card__desc {
  height: 100%;
  padding: 32px 12px;
}

.card__header {
  text-align: center;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  display: block;
}

.card__header:before {
  content: counter(card-list-item-counter) ". ";
}

.card__item {
  list-style-type: disc;
  list-style-position: inside;
}

.card__item::marker {
  content: "•";
}

.card__text {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card__text:not(:last-child) {
  margin-bottom: 4px;
}

@media (width >= 768px) {
  .works__h2 {
    text-align: center;
  }

  .card-list__item {
    flex-basis: calc(50% - 12px);
  }
}

@media (width >= 1024px) {
  .works__h2 {
    margin-bottom: 40px;
  }

  .card-list {
    gap: 44px;
  }

  .card-list__item {
    flex-basis: calc(33.3333% - 29.3333px);
  }
}

.feature {
  position: relative;
  overflow: clip visible;
}

.feature__container {
  flex-direction: column;
  gap: 80px;
  display: flex;
}

.h2--feature {
  margin-bottom: 16px;
}

.feature__section {
  flex-shrink: 0;
}

.feature-list {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.feature-list__item {
  align-items: baseline;
  gap: 8px;
  font-size: 24px;
  line-height: normal;
  display: flex;
}

.feature-list__icon {
  border: 1px solid var(--border-color);
  background: var(--background-light-color);
  border-radius: 100px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.feature__picture {
  margin-left: auto;
  padding-left: 24px;
  position: relative;
}

.feature__img {
  object-fit: contain;
  object-position: top left;
  width: 435px;
  max-width: none;
  height: 588px;
  display: block;
  position: relative;
}

.feature__picture:before {
  content: "";
  z-index: -1;
  background: url("feature-cirlce.45759354.svg");
  width: 336px;
  height: 298px;
  position: absolute;
  top: -18px;
  left: -93px;
}

@media (width >= 768px) {
  .feature__section:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (width >= 1024px) {
  .feature__container {
    flex-direction: row;
    justify-content: start;
    gap: 0;
  }

  .h2--feature {
    margin-bottom: 40px;
  }

  .feature__picture {
    padding-left: 0;
  }

  .feature__picture:before {
    top: 112px;
    left: 136px;
    transform: translate(-100%, -100%);
  }

  .feature__img {
    width: 477px;
    height: 652px;
  }

  .feature__section:first-child, .feature__section:nth-child(3) {
    max-width: 304px;
  }

  .feature__section:first-child {
    order: 1;
  }

  .feature__section:nth-child(2) {
    order: 3;
    position: relative;
  }

  .feature__section:nth-child(3) {
    order: 2;
    margin-left: 24px;
  }
}

.hero {
  padding-top: 2.0625em;
  overflow: clip visible;
}

.hero__header {
  justify-content: center;
  display: flex;
}

.hero__logo {
  flex-shrink: 0;
  align-self: start;
  width: 88px;
  height: 40px;
  margin: .8125px 1.93753em auto .625em;
  display: block;
}

.hero__title {
  text-align: left;
  margin: 0;
}

.hero__text {
  text-align: center;
  margin: 16px 0 0;
}

.hero__img, .hero__contact-nav {
  margin-top: 40px;
}

.hero__view-nav {
  justify-content: center;
  margin: 40px auto 0;
}

.hero__text.hero__text--small {
  font-size: 16px;
}

.hero__accent-text {
  background: url("ellipse.c3f01048.svg") center / contain no-repeat;
  padding: 10px 4px;
  position: relative;
}

.hero__background {
  width: fit-content;
  margin-top: 1.93753em;
  margin-left: auto;
  padding-left: 30px;
  position: relative;
}

.hero__background:before, .hero__background:after {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
}

.hero__background:before {
  background: url("hero-circle.bff04712.svg") center / contain no-repeat;
  width: calc(22.6316% - 6.78947px);
  height: 26.8421%;
  top: 12.3684%;
  left: calc(36px - 20%);
  transform: rotate(30deg);
}

.hero__background:after {
  background: url("hero-circle-2.3b9efd65.svg") center / contain no-repeat;
  width: calc(88.4211% - 26.5263px);
  height: 78.4211%;
  margin-left: auto;
  bottom: -3.94737%;
  left: calc(33.5526px - 11.8421%);
}

.hero__background--desktop {
  display: none;
}

.hero__image {
  object-fit: cover;
  object-position: top left;
  width: 100%;
  min-width: 380px;
  max-width: 632px;
  height: 100%;
  min-height: 380px;
  max-height: 632px;
  display: block;
  overflow: visible;
}

.hero-nav__link span:before, .view:before {
  content: "";
  position: absolute;
}

.hero-nav__link {
  color: var(--accent-color);
  align-items: center;
  gap: 7px;
  font-weight: 700;
  display: flex;
}

.hero-nav__link span {
  position: relative;
}

.hero-nav__link span:before {
  background: var(--accent-color);
  width: 100%;
  height: 1px;
  transition: all .35s;
  bottom: 0;
  left: 0;
  transform: scale(0);
}

.hero-nav__link:hover span:before {
  transform: scale(1);
}

@media screen and (width >= 410px) {
  .hero__background {
    margin-right: auto;
  }
}

@media screen and (width >= 768px) {
  .hero__header {
    display: block;
  }

  .hero__logo {
    margin: auto;
  }

  .hero__title {
    text-align: center;
    margin-top: 1.5rem;
  }
}

@media screen and (width >= 1024px) {
  .hero {
    padding-top: 6.25em;
    padding-bottom: 1.6875em;
  }

  .hero__container {
    justify-content: space-between;
    align-items: center;
    gap: 4%;
    display: flex;
  }

  .hero__background--mobile {
    display: none;
  }

  .hero__background--desktop {
    margin: 0;
    padding: 0;
    display: block;
  }

  .hero__background:before {
    width: 22.6316%;
    top: 8.38608%;
    left: auto;
    right: -6.59463%;
    transform: rotate(0);
  }

  .hero__background:after {
    width: 88.4211%;
    height: 78.4211%;
    bottom: -3.94737%;
    left: -11.8421%;
  }

  .hero__wrapper {
    flex-direction: column;
    flex: 50%;
    align-items: start;
    max-width: 537px;
    display: flex;
  }

  .hero__view-nav {
    justify-content: start;
    width: 100%;
    margin-left: 0;
  }

  .hero__logo {
    margin: 0;
  }

  .hero__title {
    margin-top: 40px;
  }

  .hero__title, .hero__text {
    text-align: left;
  }

  .hero__contact-nav {
    flex-direction: row;
    gap: 8px;
  }
}

.footer {
  text-align: center;
  background: #9b6109;
  padding: 40px 0;
}

.copyright {
  margin-bottom: 24px;
}

.footer__txt {
  color: #fff;
  line-height: 1.3;
}

.footer__legal > a {
  text-decoration: underline;
  display: inline;
}

.footer__legal > a:last-child {
  margin-left: 24px;
}

.socials {
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
  display: flex;
}

.socials__link {
  width: 36px;
  height: 36px;
}

.swiper {
  margin-top: 80px;
  position: relative;
}

.swiper--menu {
  margin-top: 6.5em;
}

.h2--swiper {
  text-align: center;
  margin-bottom: 30px;
}

.swiper__btn {
  background: #fff3df;
  border-radius: 100%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-top: 132px;
  transition: background .35s;
  display: none;
}

.swiper__btn:disabled, .swiper__btn:disabled:hover {
  cursor: auto;
  background: #faf6ee;
}

.swiper__btn:focus, .swiper__btn:hover {
  background: #ffe5b9;
}

.swiper__btn:last-of-type > svg {
  transform: rotate(180deg);
}

.swiper__wrapper {
  justify-content: center;
  gap: 24px;
  display: flex;
}

.swiper__list {
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 16px;
  padding: 0 27px;
  display: flex;
  overflow-x: auto;
}

.swiper__list::-webkit-scrollbar {
  display: none;
}

.swiper__item {
  scroll-snap-align: center;
  flex: 0 0 97%;
}

.swiper__figure {
  border-radius: 16px;
  margin: 0;
  overflow: hidden;
}

.swiper__img {
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
  width: 100%;
  height: 280px;
}

.swiper__caption {
  letter-spacing: 0;
  text-align: center;
  background: #fff4e2;
  height: 100%;
  min-height: 180px;
  padding: 32px 22px 0;
  font-family: Fira Sans;
  font-size: 24px;
  line-height: 29px;
}

.swiper__title {
  letter-spacing: 0;
  font-weight: 700;
}

.swiper__desc {
  margin: 8px 0 0;
  font-weight: 400;
}

@media screen and (width >= 768px) {
  .swiper__wrapper {
    padding: 0 27px;
  }

  .swiper__btn {
    display: inline-flex;
  }

  .swiper__wrapper {
    margin: auto;
  }

  .swiper__list {
    padding: 0;
  }

  .swiper__item {
    scroll-snap-align: start;
    flex-basis: 100%;
  }
}

@media screen and (width >= 1024px) {
  .swiper {
    margin-top: 100px;
  }

  .swiper--menu {
    margin-top: 9.375em;
  }

  .swiper__wrapper {
    max-width: 1440px;
    padding: 0 76px;
  }

  .swiper__item {
    scroll-snap-align: start;
    flex-basis: calc(50% - 8px);
  }
}

@media screen and (width >= 1300px) {
  .swiper__item {
    flex-basis: calc(33.3333% - 10.6667px);
  }
}

.h2--swiper {
  margin-bottom: 40px;
}

.text {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/*# sourceMappingURL=index.3d915ee3.css.map */
