.feature {
  position: relative;
  overflow-x: clip;
  overflow-y: visible;
}

.feature__container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.h2--feature {
  margin-bottom: 16px;
}

.feature__section {
  flex-shrink: 0;
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.feature-list__item {
  display: flex;
  align-items: baseline;
  gap: 8px;

  font-size: 24px;
  line-height: normal;
}

.feature-list__icon {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 100px;
  border: 1px solid var(--border-color);
  background: var(--background-light-color);
}

.feature__picture {
  position: relative;
  margin-left: auto;
  padding-left: 24px;
}

.feature__img {
  position: relative;
  display: block;
  width: 435px;
  height: 588px;
  max-width: none;
  object-fit: contain;
  object-position: top left;
}

.feature__picture::before {
  content: '';
  position: absolute;
  top: -18px;
  left: calc(-116px + 23px);
  width: 336px;
  height: 298px;
  z-index: -1;
  background: url('../../assets/images/feature-cirlce.svg');
}

@media (min-width: 768px) {
  .feature__section:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1024px) {
  .feature__container {
    flex-direction: row;
    justify-content: start;
    gap: 0;
  }

  .h2--feature {
    margin-bottom: 40px;
  }

  .feature__picture {
    padding-left: 0;
  }

  .feature__picture::before {
    top: 112px;
    left: 136px;
    transform: translate(-100%, -100%);
  }

  .feature__img {
    width: 477px;
    height: 652px;
  }

  .feature__section:nth-child(1),
  .feature__section:nth-child(3) {
    max-width: 304px;
  }

  .feature__section:nth-child(1) {
    order: 1;
  }

  .feature__section:nth-child(2) {
    order: 3;
    position: relative;
  }

  .feature__section:nth-child(3) {
    order: 2;
    margin-left: 24px;
  }
}
